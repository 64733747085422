.label-login{

  .ant-form-item label{
    color:white !important;
  }

  .forgot-password{
    color:white !important;
  }
}

.ant-menu-item-group-title{
  display:none !important;
}

.ant-menu-item, .ant-menu-submenu{
  margin-bottom: 20px !important;
}
.card-gates{
  .ant-card-body{
    padding: 2px !important;
  }
}
