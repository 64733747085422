.ant-input-number {
    border: 1px solid $input-border-color;
    line-height: $input-line-height;
    height: $select-height;
    color: $body-color;
    font-size: $font-size-base;
    border-radius: $input-border-radius;

    &:hover,
    &:focus {
        border-color: $input-theme-color;
    }

    &.ant-input-number-disabled {
        &:hover,
        &:focus {
            border-color: $input-border-color;
        }
    }
}

.ant-input-number-input {
    height: $select-height - 0.125rem;
}

.ant-input-number-lg {
    height: $select-height-lg;

    .ant-input-number-input {
        height: $select-height-lg - 0.125rem;
    }
}

.ant-input-number-sm {
    height: $select-height-sm;

    .ant-input-number-input {
        height: $select-height-sm - 0.125rem;
    }
}

.ant-input-number-handler-wrap {
    border-left: 1px solid $input-border-color;
}    

.ant-input-number-handler {

    &:hover {
        .ant-input-number-handler-down-inner,
        .ant-input-number-handler-up-inner {
            color: $input-theme-color;
        }
    }

    &:active {
        background-color: $gray-lightest;
    }
} 

.ant-input-number-handler-down {
    border-top: 1px solid $input-border-color;
}

.ant-input-number-focused {
    border-color: $input-theme-color;
}

.ant-input-number-handler-wrap {
    border-radius: 0 $input-border-radius $input-border-radius 0;
}